/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.tabs {
  position: relative;
  border: 0;
  background: none;
  overflow: hidden;
  /**
 * Asset Index Page Tab Navigation Variant
 * @author Charles Harwood
 */
  /**
 * Footer Tabs Component - CH
 */
  /**
   * Map Switcher Tabs Variant - CH
   */
}

.asset-index--industrial .tabs.tabs--assets {
  display: none;
}

.tabs.tabs--assets .tabs-heading li {
  margin: 0;
  border: 0;
  border-bottom: 2px solid transparent;
  background: none;
}

.tabs.tabs--assets .tabs-heading li > div {
  padding: 10px;
}

.tabs.tabs--assets .tabs-heading li > div .field-heading {
  color: #55565b;
  font-size: 1em;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .tabs.tabs--assets .tabs-heading li > div {
    padding: 5px;
  }
}

.tabs.tabs--assets .tabs-heading li .component.content {
  margin: 0;
}

.tabs.tabs--assets .tabs-heading li:not(:first-child) {
  margin-left: 4rem;
}

@media (max-width: 767px) {
  .tabs.tabs--assets .tabs-heading li:not(:first-child) {
    margin-left: 1rem;
  }
}

.tabs.tabs--assets .tabs-heading li.active {
  border-bottom-color: #ee2737;
}

.tabs.tabs--assets .tabs-heading li.active > div .field-heading {
  color: #000000;
}

.tabs.tabs--assets .tabs-container {
  height: 0;
  margin-bottom: 7.8rem;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.tabs.tabs--assets .tabs-container > * {
  display: none;
}

.tabs.footer-tabs .tabs-inner {
  margin-left: -10px;
}

.tabs.footer-tabs .tabs-heading li {
  transition: font-weight 0.3s ease;
  border: 0;
  background: none;
  will-change: font-weight;
}

.tabs.footer-tabs .tabs-heading li .component.content {
  margin-bottom: 12.5px;
  transition: border 0.3s ease;
  border-bottom: 2px solid transparent;
}

.tabs.footer-tabs .tabs-heading li > div {
  padding: 3px 12.5px;
}

.tabs.footer-tabs .tabs-heading li > div .field-heading {
  padding-bottom: 5px;
}

.tabs.footer-tabs .tabs-heading li.active {
  font-weight: bold;
}

.tabs.footer-tabs .tabs-heading li.active .component.content {
  border-bottom-color: #55565b;
}

.tabs.footer-tabs .tabs-container {
  border-top: 0;
}

.tabs.footer-tabs .tabs-container .tab .content p {
  margin-top: 0;
  margin-bottom: 1em;
}

.tabs.footer-tabs .tabs-container .tab .content address {
  font-size: 1em;
  line-height: 1.75em;
}

.tabs.tabs-map-switcher .tab {
  padding: 0;
}

.tabs.tabs-map-switcher .tabs-heading {
  display: flex;
  justify-content: center;
  padding: 2.6rem;
  background-color: #252849;
}

.tabs.tabs-map-switcher .tabs-heading:before {
  display: block;
  flex: 0 0 auto;
  margin-right: 3.8rem;
  color: #fff;
  font-weight: bold;
  content: 'I Want to Explore';
}

.tabs.tabs-map-switcher .tabs-heading li {
  flex: 0 0 auto;
  margin: 0 1rem;
  transition: opacity 0.3s ease;
  border: 0 !important;
  background: 0 !important;
  opacity: 0.4;
  will-change: opacity;
}

.tabs.tabs-map-switcher .tabs-heading li:hover, .tabs.tabs-map-switcher .tabs-heading li.active {
  opacity: 1;
}

.tabs.tabs-map-switcher .tabs-heading li .component.content {
  margin: 0;
  color: #fff;
}

.tabs.tabs-map-switcher .tabs-heading li > div .field-heading {
  position: relative;
  padding-left: 1.75em;
  font-size: 1.25em;
  line-height: 1.2em;
}

.tabs.tabs-map-switcher .tabs-heading li > div .field-heading:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.2em;
  height: 1.2em;
  border: 2px solid #ffffff;
  border-radius: 100%;
  content: '';
}

.tabs.tabs-map-switcher .tabs-heading li > div .field-heading:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0.5em;
  height: 0.5em;
  margin: 0.35em;
  transition: opacity 0.3s ease;
  border-radius: 100%;
  background-color: #FFFFFF;
  content: '';
  opacity: 0;
}

.tabs.tabs-map-switcher .tabs-heading li.active > div .field-heading:after {
  opacity: 1;
}

@media (max-width: 767px) {
  .tabs.tabs-map-switcher .tabs-heading {
    flex-wrap: wrap;
    padding: 2.6rem 1.5rem;
  }
  .tabs.tabs-map-switcher .tabs-heading:before {
    flex-basis: 100%;
    max-width: 100%;
    margin: 0 0 1.5rem;
    text-align: center;
  }
}

.tab-slider {
  position: absolute;
  width: 15px;
  border: 2px solid #89C6CC;
  background: #f7f7f7;
  text-indent: -9999px;
  cursor: pointer;
  vertical-align: center;
  z-index: 10;
}

.tabs-heading {
  overflow: hidden;
}

.tabs-heading li {
  display: block;
  width: auto;
  margin-left: 1px;
  float: left;
  border-top: 2px solid #f7f7f7;
  border-left: 1px solid #f7f7f7;
  border-right: 1px solid #f7f7f7;
  border-bottom: 1px solid #89C6CC;
  background: #f7f7f7;
  cursor: pointer;
}

.tabs-heading li > div {
  display: block;
  padding: 3px 10px;
  color: #222;
  text-decoration: none;
}

.tabs-heading li > div .field-heading {
  font-size: 16px;
}

.tabs-heading li .component.content {
  margin: 5px 0;
}

.tabs-heading li.active {
  border-top: 2px solid #89C6CC;
  border-bottom: 1px solid #89C6CC;
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
  background: #FFFFFF;
}

.tabs-heading li.active a {
  color: #000;
}

.tabs-heading li:hover {
  border-top: 2px solid #89C6CC;
  background: #FFFFFF;
}

.tabs-heading li:first-child {
  margin-left: 0;
}

.tabs-container {
  margin-top: -1px;
  border-top: 1px solid #89C6CC;
  background: transparent;
}

.tab {
  display: none;
  padding: 10px;
}

.tab.active {
  display: block;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.tabs.tabs-bottom .tabs-container {
  margin: 0;
}

.tabs.tabs-bottom .tabs-heading {
  position: relative;
  margin-top: -1px;
}

.tabs.tabs-bottom .tabs-heading li {
  margin-bottom: 0;
  border-top: 0px solid #ffffff;
}

.tabs.tabs-bottom .tabs-heading li a {
  border: none;
}

.tabs.tabs-bottom .tabs-heading li.active {
  border-top: none;
  border-bottom: 2px solid #89C6CC;
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
}

.tabs.tabs-bottom .tabs-heading li.active a {
  border: none;
  position: relative;
}

.tabs.tabs-bottom .tabs-heading li:hover {
  border-bottom: 2px solid #89C6CC;
  background: #FFFFFF;
}

.tabs.tabs-bottom .tabs-container {
  border-bottom: 1px solid #89C6CC;
  border-top: none;
  background: transparent;
  margin-bottom: 1px;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.tabs.tabs-scrollable .tabs-heading {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
}

.tabs.tabs-scrollable .tabs-container {
  margin: 0;
}

.tabs.tabs-scrollable .prev {
  left: 0;
  background: #e4e4e4 url("../Images/arrow-left.png") center center no-repeat;
}

.tabs.tabs-scrollable .next {
  right: 0;
  background: #e4e4e4 url("../Images/arrow-right.png") center center no-repeat;
}

.tabs.tabs-scrollable.tabs-bottom .wrapper {
  margin-top: 2px;
  padding-bottom: 6px;
}

.tabs.tabs-scrollable.tabs-bottom .next {
  top: 1px;
}

.tabs.tabs-scrollable.tabs-bottom .prev {
  top: 1px;
}

.tabs.tabs-scrollable.tabs-bottom .tabs-heading {
  margin-top: -4px;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.tabs.tabs-vertical.tabs-vertical-right .tabs-heading {
  float: right;
  margin-left: -1px;
}

.tabs.tabs-vertical.tabs-vertical-right .tabs-heading li {
  border-top: 1px solid #f7f7f7;
  border-left: 1px solid #f7f7f7;
  border-right: 2px solid #89C6CC;
  border-bottom: 1px solid #f7f7f7;
}

.tabs.tabs-vertical.tabs-vertical-right .tabs-heading li.active {
  border-right: 2px solid #89C6CC;
  border-left: 1px solid #89C6CC;
}

.tabs.tabs-vertical.tabs-vertical-right .tabs-heading li.active a {
  border: none;
}

.tabs.tabs-vertical.tabs-vertical-right .tabs-heading li:hover {
  border-left: 1px solid #89C6CC;
}

.tabs.tabs-vertical.tabs-vertical-right .tabs-container {
  float: right;
  border-right: 1px solid #89C6CC;
  border-top: none;
  margin-left: -1px;
  border-left: none;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.tabs.tabs-vertical .tabs-heading {
  width: 30%;
  float: left;
}

.tabs.tabs-vertical .tabs-heading li {
  margin: 0 0 2px 0;
  display: block;
  width: 100%;
  border-top: 1px solid #f7f7f7;
  border-left: 2px solid #f7f7f7;
  border-right: 1px solid #89C6CC;
  border-bottom: 1px solid #f7f7f7;
}

.tabs.tabs-vertical .tabs-heading li.active {
  border-left: 2px solid #89C6CC;
}

.tabs.tabs-vertical .tabs-heading li.active a {
  border: none;
}

.tabs.tabs-vertical .tabs-heading li:first-child {
  margin-top: 0;
}

.tabs.tabs-vertical .tabs-heading li:hover {
  border-left: 2px solid #89C6CC;
}

.tabs.tabs-vertical .tabs-container {
  margin: 0;
  float: left;
  width: 70%;
  margin-left: -1px;
  border-top: none;
  border-left: 1px solid #89C6CC;
}
